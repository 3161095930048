<template>
  <div>
    <Header />
    <slot />
    <Footer />
  </div>
</template>

<script>
import Header from "@index/components/header/header";

export default {
  components: {
    Header,
    Footer: () => import("@index/components/footer/footer.vue"),
  },
};
</script>
