export default {
  namespaced: true,
  state: () => ({
    profile: {
      phone: null,
    },
    contracts: [],
  }),
  actions: {
    login({ commit }, payloadPhone) {
      const phone = payloadPhone.replace(/[+,(,),-]/g, "");
      return this.$infra.finvin
        .login({
          phone,
          recaptchaToken: "test",
        })
        .then((res) => {
          if (res.expire_at) {
            commit("setPhone", phone);
            return res;
          }

          throw res;
        })
        .catch((e) => {
          const errors = (e || {}).errors || {};
          throw errors;
        });
    },
    confirm({ state, dispatch, commit }, code) {
      return this.$infra.finvin
        .confirm({
          phone: state.profile.phone,
          code,
          recaptchaToken: "test",
        })
        .then((res) => {
          if (res.data) {
            commit("setProfile", res.data);
            dispatch("refresh");
            return res;
          }

          throw res;
        })
        .catch((e) => {
          const errors = (e || {}).errors || {};
          throw errors;
        });
    },
    resend({ dispatch, state }) {
      return dispatch("login", state.phone);
    },
    user({ dispatch, commit }) {
      return this.$infra.finvin
        .user()
        .then((res) => {
          if (res.data) {
            commit("setProfile", res.data);
            dispatch("refresh");
            return res;
          }

          throw res;
        })
        .catch((e) => {
          dispatch("logout");
          throw e;
        });
    },
    contractsGet({ dispatch, commit }) {
      return this.$infra.finvin
        .contracts()
        .then((res) => {
          if (res.data) {
            commit("setContracts", res.data);
            dispatch("refresh");
            return res;
          }

          throw res;
        })
        .catch((e) => {
          dispatch("logout");
          throw e;
        });
    },
    contractDeactivate({ dispatch }, contractId) {
      this.$infra.finvin.contractDeactivate(contractId).then(() => {
        dispatch("contractsGet");
        this.$infra.tracker.step3submitRefusal()
      });
    },
    logout({ commit }) {
      clearTimeout(window.refreshFinVin);
      localStorage.removeItem("authorization");
      commit("setProfile", {});
      commit("setContracts", []);
    },
    refresh() {
      clearTimeout(window.refreshFinVin);

      window.refreshFinVin = setTimeout(() => {
        this.$infra.finvin.refresh().then((res) => {
          console.log("refresh", res);
        });
      }, 30 * 1000);
    },
  },
  mutations: {
    setPhone(state, phone) {
      state.profile.phone = phone;
    },
    setProfile(state, payload) {
      state.profile = payload;
    },
    setContracts(state, payload) {
      state.contracts = payload;
    },
  },
  getters: {
    profile(state) {
      return state.profile;
    },
    contracts(state) {
      return state.contracts;
    },
    isLoggedIn(state) {
      return Boolean(state.profile.lastname);
    },
  },
};
