import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import infraLayer from "@frontend/allspark/infra/index_finvin";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: { auth },
  strict: true,
});

store.$infra = infraLayer.infra;

export default store;
