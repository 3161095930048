<template>
  <div>
    <Header class="width-932__header" />
    <div class="width-932__content">
      <slot />
    </div>
    <Footer :variant="$route.meta.footerVariant" />
  </div>
</template>

<style>
.width-932__header {
  max-width: 972px;
  padding: 0 20px;
  margin: 0 auto;
}

@media (max-width: 640px) {
  .width-932__header {
    padding: 0;
  }
}

.width-932__content {
  padding: 20px 20px 100px;
  max-width: 972px;
  margin: 0 auto;
}

</style>

<script>
import Header from "@index/components/header/header";

export default {
  components: {
    Header,
    Footer: () => import("@index/components/footer/footer.vue"),
  },
};
</script>
