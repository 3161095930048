<template>
  <div>
    <slot />
    <Footer :variant="$route.meta.footerVariant" />
  </div>
</template>

<script>
export default {
  components: {
    Footer: () => import("@index/components/footer/footer.vue"),
  },
};
</script>
