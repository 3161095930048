<template>
  <div>
    <MobileMenu
      v-if="menu"
      @close="menu = false"
    />
    <header>
      <div class="header-wrapper">
        <div class="logo">
          <img
            src="/logo.svg"
            alt="svg"
          >
        </div>
        <slot />
        <nav @click="openMenu" />
        <div class="nav-desktop">
          <ul>
            <li>
              <a
                v-if="isAuth"
                href="/lk"
              >
                Личный кабинет
                <span class="icon" />
              </a>
              <a
                v-else
                href="/login"
              >
                {{
                  $route.name === 'Main'
                    ? 'Личный кабинет'
                    : 'Войти'
                }}
                <span class="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {
    MobileMenu: () => import("./mobileMenu"),
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    isAuth() {
      return false;
    },
    activeLink() {
      return this.$store.state.headerActiveLink;
    },
  },
  methods: {
    openMenu() {
      this.menu = true;
    },
  },
};
</script>

<style lang="scss" src="./header.scss"/>
