<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
export default {
  name: "App",
  computed: {
    layout() {
      return this.$route.meta.layout || "layout-default";
    },
  },
  beforeRouteEnter() {
    document
      .querySelector(".loader-wrapper")
      .setAttribute("style", "display:block");
    document.body.setAttribute(
      "style",
      "overflow:hidden;pointer-events:none;user-select:none;"
    );
  },
  beforeRouteUpdate(){
    this.updateLs();
  },
  mounted() {
    this.updateLs();

    document
      .querySelector(".loader-wrapper")
      .setAttribute("style", "display:none");
    document.body.removeAttribute("style");
  },
  methods:{
    updateLs(){
      const query = this.$route.query || {};

      if(URLSearchParams){
        const params = new URLSearchParams(window.location.search);
        for (let param of params) {
          query[param[0]] = param[1];
        }
      }
      
      if(query.utm_source && localStorage){
        localStorage.setItem("utm_source", query.utm_source || "");
      }

      if(localStorage){
        localStorage.setItem("utm_source_query", JSON.stringify(query));
      }
    }
  }
};
</script>
